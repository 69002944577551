<template>
	<el-menu
		class="el-menu-demo"
		mode="horizontal"
		style="margin:30px auto 0px auto;width: 80vw;min-width: 1300px;"
		:ellipsis="false"
	>
		<router-link to="/">
			<el-image style="width: 160px; height: 80px; margin-top: -20px;margin-right: 3vw;"
			          :src="logoUrl" fit="fill"/>
		</router-link>
		
		<router-link to="/">
			<el-menu-item index="1" style="font-size: 15px;color: #141517;font-weight: 600;">Home</el-menu-item>
		</router-link>
		
		
		<!--	分类	-->
		<el-sub-menu index="2" style="">
			<template #title>
				<span style="font-size: 15px;color: #141517;font-weight: 600;">Shop by Category</span>
				
			</template>
			<div style="display:flex">
				<div>
					<template v-for="item in categoryOptions" :key="item.id">
						<router-link :to="{ path: '/pages/productList', query: { categoryId: item.id } }">
							<el-menu-item :index="`category-`+item.id">{{item.name}}</el-menu-item>
						</router-link>
					</template>
				</div>
				
				<el-image :src="require('@/assets/img/category.jpg')" style="margin: 30px;width: 400px;"></el-image>
			</div>
			
		</el-sub-menu>
		
		<!--	age	-->
		<el-sub-menu index="3" class="age-menu" popper-class='age-menu' >
			<template #title><span style="font-size: 15px;color: #141517;font-weight: 600;">Shop by Age</span></template>
			
				<div style="display: flex;width: 1200px;margin: 0 auto;">
					<div v-for="(item,index) in ageOptions" :key="index" style="margin: 10px 20px;text-align: center;">
						<router-link :to="{ path: '/pages/productList', query: { labelId: item.id } }">
							<el-image :src="labelImg[index]" style="width: 200px;height: 200px;margin: auto;border-radius: 10px;"></el-image>
							<!-- <div style="text-align: center;"> -->
								<el-menu-item :index="`age-`+item.id" style="display: flex; justify-content: center;" >{{item.name}}</el-menu-item>
							<!-- </div> -->
						</router-link>
					</div>
				</div>
				<!-- <el-image :src="require('@/assets/img/I909-1.jpg')" style="margin: 30px;"></el-image> -->
			
		</el-sub-menu>
		
		<!--	清仓	-->
		<router-link to="/pages/clearance">
			<el-menu-item index="4" style="font-size: 15px;color: #141517;font-weight: 600;">
				<!-- <el-badge value="hot"  class="item"> -->
					Today's Deals
				<!-- </el-badge> -->
			</el-menu-item>
		</router-link>
		
		<!--	支持	-->
		<!-- <router-link to="/pages/support?type=About Us"> -->
			<!-- <el-menu-item index="5" style="font-size: 15px;color: #141517;font-weight: 600;">Customer Service</el-menu-item> -->
			
			<el-sub-menu index="5" style="">
				<template #title>
					<span style="font-size: 15px;color: #141517;font-weight: 600;">Customer Service</span>
					
				</template>
				<div style="display:flex">
					<div>
						<template v-for="item in supportOptions" :key="item.id">
							<router-link :to="`/pages/support?type=${item.title}`">
								<el-menu-item :index="`support-`+item.title">{{item.title}}</el-menu-item>
							</router-link>
						</template>
					</div>
					
					<!-- <el-image :src="require('@/assets/img/A510-1.jpg')" style="margin: 30px;"></el-image> -->
				</div>
				
			</el-sub-menu>
		<!-- </router-link> -->
		
		<!--	比较	-->
		<!-- <router-link to="/pages/compare">
			<el-menu-item index="6" style="font-size: 15px;color: #141517;font-weight: 600;">Compare</el-menu-item>
		</router-link> -->
		
		
		<!--	查询	-->
		<div style="width: 15vw; margin-left: 3vw; height: 50px;">
			<el-input
				v-model="searchKeyword"
				style="width: 15vw; height: 50px;vertical-align: middle;"
				placeholder="Search "
				@keydown.enter="handleSearch"
				:suffix-icon="Search"
				>
				<!-- <template #append>
					<el-button type="primary" :icon="Search" @click="handleSearch" round>
					</el-button>
				</template> -->
			</el-input>
		</div>
		
		<!-- <div > -->

			<!--	用户	-->
			<!-- <router-link to="/pages/my"> -->
				
				<el-button text circle style="margin-left: 1vw;margin-top: 10px;" @click="toCustomer">
					<el-icon size="30" style="vertical-align: middle">
						<User/>
					</el-icon>
				</el-button>
			<!-- </router-link> -->
			
			<router-link to="/pages/my?type=wishlist">
				<el-button text circle style="margin-left: 1vw;margin-top: 10px;">
					<el-image :src="collectUrl" fit="fill" style="width: 25px;height: 25px;"/>
				</el-button>
			</router-link>
			

			<el-badge :value="cartCacheStore.calcResult?.totalNumber" :max="99" class="item">
				<el-button text circle style="margin-left: 1vw;margin-top: 10px;z-index: 999;top: 10px;" @click="cartCacheStore.cartDrawer = true">
					<el-icon size="30" style="vertical-align: middle">
						<ShoppingCartFull/>
					</el-icon>
				</el-button>
			</el-badge>
		<!-- </div> -->
		
	</el-menu>
	
	<!-- 购物车	-->
	<el-drawer v-model="cartCacheStore.cartDrawer"  show-close :size="480">
		<template #header>
			<h4>MY CART</h4>
			<!-- <div>{{JSON.stringify(cartCacheStore.calcResult)}}</div> -->
		</template>
		<template #default>
			<el-scrollbar v-if="cartCacheStore.cartList.length > 0">
				<div style="border-bottom: #e5e6e9 1px solid;padding: 10px;margin-bottom: 10px;height: 150px;display: flex;"
				     v-for="item in cartCacheStore.cartList" :key="item.id">
					<div style="margin-right: 20px;margin-top: 20px;">
						<el-checkbox v-model="item.checked" style="zoom: 2;"/>
					</div>
					<router-link :to="`/pages/productDetail?productId=${item.productId}`">
						<div style="margin-right: 20px;">
							<el-image :src="item.imgs.split(',')[0]" style="width: 100px;height: 100px;"></el-image>
						</div>
					</router-link>
					
					<div style="font-size: 14px;color: #141517;font-weight: 600;margin-top: 10px;text-align: left;">
						<div style="width: 160px;">{{ item.description }}</div>
						<div style="height: 20px;">
							<el-input-number v-model="item.num" :min="1" :max="item.inventory" :step-strictly="true" width="30px"/>
						</div>
						<div style="margin-top: 15px;">
							<!-- <template v-for="spec in item.specDesc" :key="spec.key"> -->
								<span style="color: #969697;font-weight: 500;"> {{ setSpec(item.specDesc) }} </span>
							<!-- </template> -->
						</div>
						<div style="margin-top: 5px;">${{ floatToFixed2(item.price) }}</div>
					</div>
					<div style="margin-top: 30px;margin-left: 70px;">
						<el-button type="text" @click="cartCacheStore.removeCartItem(item)">
							<el-icon size="20" style="vertical-align: middle">
								<Close/>
							</el-icon>
						</el-button>
					</div>
				
				</div>
			</el-scrollbar>
			<div v-else style="">
				<p style="margin-top: 200px; font-size: 24px; text-align: center">Your cart is empty</p>
			</div>
		</template>
		<template #footer>
			
			<!-- <div style="flex: auto;margin-right: 60px;margin-bottom: 30px;">
				<span style="font-size: 18px;color: #141517;font-weight: 600;">
					Total: ${{floatToFixed2(cartCacheStore.calcResult?.payAmt) }}
				</span>
			</div> -->
			<div style="display: flex;">
				<div style="text-align: left;">
					<el-checkbox v-model="checkAll" style="zoom: 1.2;float:left" @change="setCheckAll">CHECK ALL</el-checkbox>
					<el-popconfirm title="Remove selected items?" @confirm="removeSelectCart" width="210">
						<template #reference>
							<el-button text type="danger" :disabled="confirmDisabled" size="small" >REMOVE SELECTED ITEMS</el-button>
						</template>	
						
					</el-popconfirm>
					
				</div>
				
				<div style="margin-right: 30px;margin-bottom: 30px;width: 200px;text-align: right;">
					<span style="font-size: 18px;color: #141517;font-weight: 600;">
						Total: ${{floatToFixed2(cartCacheStore.calcResult?.checkTotal) }}
					</span>
				</div>
			</div>
			
			<div style="flex: auto;margin-bottom: 100px;">
				<el-button type="primary" :disabled="confirmDisabled" @click="handleConfirm" size="large">GO TO CHECK OUT</el-button>
			</div>
		</template>
	</el-drawer>


	<div style="position: fixed;z-index: 999;bottom: 100px;right: 50px;" v-show="cartShow">
		<el-badge :value="cartCacheStore.calcResult?.totalNumber" :max="99" class="item">
			<el-button text circle style="margin-left: 1vw;margin-top: 10px;" @click="cartCacheStore.cartDrawer = true">
				<el-icon size="40" style="vertical-align: middle">
					<ShoppingCartFull/>
				</el-icon>
			</el-button>
		</el-badge>
	</div>
</template>

<script setup>
import {Search} from '@element-plus/icons-vue'
import {useRouter, useRoute} from "vue-router"
import logoUrl from "@/assets/img/logo3.gif"
import collectUrl from "@/assets/img/collect.png"
import {ref, defineOptions, watch, computed,onMounted} from "vue";
import useCartCache from "@/store/cart"
import {floatToFixed2} from "@/utils/priceUtil"
import useCache from "@/utils/useCache"
import {getAgeList, getCategoryList, getProductAllByPage, getProductLabelAll, getSpuAll} from "@/api/spu"
import useCustomerStore from "@/store/customer"
import {getAccessToken} from "@/utils/auth";

import {getVisits} from "@/api/base/index"

import {getAllByPage}  from "@/api/article/index";

defineOptions({
	name: 'PcHeader'
})

const router = useRouter();
const route = useRoute();
const cartCacheStore = useCartCache();
const searchKeyword = ref('');
const cacheStore = useCache("localStorage")
const customerStore = useCustomerStore()

const checkAll = ref(false)

// 年龄/分类
const ageOptions = ref([]);
const categoryOptions = ref([])

const supportOptions = ref([])

const cartShow = ref(false)

const labelImg = ref([
	require('@/assets/img/label/0-1.jpg'),
	require('@/assets/img/label/1-2.jpg'),
	require('@/assets/img/label/2-3.jpg'),
	require('@/assets/img/label/3-6.jpg'),
	require('@/assets/img/label/6-12.jpg'),
])

// 监听关键字并赋值
watch(() => route.query, (query) => {
	if (query.keyword){
		searchKeyword.value = query.keyword
	} else {
		searchKeyword.value = ''
	}
}, {
	immediate: true
})

onMounted(async () => {
	await getCategory(); // 获取分类
	await getAgeLabel(); // 获取年龄
	// cartCacheStore.getCurrentCartList();
	cartCacheStore.initCart();
	getVisits({});
	getSupport();

	document.addEventListener('scroll', onScroll);
})

const onScroll = () => {
	const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
	const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
	const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
	if (scrollTop >= 80) {
		cartShow.value = true;
	}else{
		cartShow.value = false;
	}
}


// 跳转(分几种)
const handleSearch = () => {
	router.push({path: "/pages/productList", query: {keyword: searchKeyword.value}})
}

// 用于判断是否禁用confirm按钮
const confirmDisabled = computed(() => {
	console.log("cartCacheStore.cartList",cartCacheStore.cartList)
	try{
		return cartCacheStore.cartList.filter(item => item.checked).length === 0
	}catch(e){
		console.log(e)
		return false;
	}
	
})


// 购物车确认，跳转下单界面
const handleConfirm = () => {
	
	// 将选中的商品存入缓存
	// cacheStore.set("products", JSON.stringify(cartCacheStore.cartList.filter(item => item.checked)))
	
	localStorage.setItem("products", JSON.stringify(cartCacheStore.cartList.filter(item => item.checked)))
	cartCacheStore.cartDrawer = false;
	router.push({path: "/pages/order"})
}

// 获取分类
const getCategory = async () => {
	const {data: {list}} = await getCategoryList({
		page: 1,
		pageSize: 20,
		conditions: []
	})
	categoryOptions.value = list;
	console.log(list, "分类数据");
}

// 获取年龄标签
const getAgeLabel = async () => {
	const {data: list} = await getAgeList({
		
		conditions: []
	})
	list.forEach((item,index) => {
		if(item.id == 9){
			
			list.splice(index,1)
		}
	})
	ageOptions.value = list;
	console.log(list, "年龄分类数据");
}

const toCustomer = () => {
	console.log(customerStore.customerInfo, "用户信息");
	if(getAccessToken()){
		router.push({path: '/pages/my'})
	}else{
		router.push({path: '/pages/login',query:{
			redirect: router.currentRoute.value.fullPath
		}})
	}
}

const setCheckAll = () => {
	// checkAll.value = !checkAll.value
	
	cartCacheStore.cartList.forEach(item => {
		item.checked = checkAll.value
	})
	console.log("checkAll", checkAll.value)
}

const setSpec = (item) => {	
	if(item){
		
		let str = '';
		while(typeof item == 'string'){
			item = JSON.parse(item)
		}
		item.forEach((value) => {
			str +=  value.key + ':' + value.value + '; '
		})
		
		return str;
	}
      
}

const removeSelectCart = () => {
	cartCacheStore.cartList.forEach(item => {
		if(item.checked){
			cartCacheStore.removeCartItem(item)
		}
	})
}

const getSupport = async () => {
	const {data:{list}} = await getAllByPage({
		page: 1,
		pageSize: 20,
		conditions: []
	})
	supportOptions.value = list
	supportOptions.value.push({title:"Track My Order"})
	supportOptions.value.push({title:"Price List"})
	supportOptions.value.push({title:"Order Form"})
	supportOptions.value.push({title:"Lead Test Report"})
	
	supportOptions.value.push({title:"Free Catalog Request"})
	supportOptions.value.push({title:"Support Online"})
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-popper.is-dark2 {
	background: #e35959;
	border: 1px solid #e35959;
	margin-left: 10px;
	vertical-align: middle;
	width: 22px;
	height: 12px;
}

.el-popper.is-dark2 .el-popper__arrow::before {
	background: #e35959;
	border: 1px solid #e35959;
}

/* body {
	@media (max-width: 1200px) {
		
		overflow-x: auto;
		
	}
} */


</style>

<style>
div.age-menu{
	position: absolute !important;
	left:50% !important;
	transform: translateX(-50%) !important;
}
</style>
